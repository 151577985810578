import React from "react"
import { graphql } from "gatsby"
import MarkdownView from "react-showdown"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { PostContainer, PostHeader } from "../components/styled/post.js"
import { Container } from "../components/styled/container"
import { AdBanner, AdLayout } from "../components/adBanner/index"

const FAQPage = ({ data }) => {
  return (
    <Layout>
      <AdLayout>
        <AdBanner slot="6517054732" />
        <Container main style={{ flex: "10 0 auto" }}>
          <SEO title="FAQ - FGCharts" description={data.strapiSeo.faqpage} />
          <PostHeader>
            <h2 style={{ marginBottom: 16 }}>Frequently Asked Questions</h2>
          </PostHeader>
          <PostContainer>
            <MarkdownView
              markdown={data.strapiFaq.content}
              options={{ table: true, emoji: true }}
            />
          </PostContainer>
        </Container>
        <AdBanner slot="6517054732" />
      </AdLayout>
    </Layout>
  )
}
export const query = graphql`
  {
    strapiFaq {
      content
    }
    strapiSeo {
      faqpage
    }
  }
`

export default FAQPage
